export const WEBSITE_CODE_LIST = {
  VACATION: 'YesAndVac' as const,
};

export const WEBSITE_CODES = [WEBSITE_CODE_LIST.VACATION] as const;

export type WebsiteCode = (typeof WEBSITE_CODES)[number];

export interface Environment {
  production: boolean;
  /**
   * You have to switch this, once your real back-end is done (used to be true)
   */
  isMockEnabled: boolean;
  /**
   * API endpoint
   */
  baseUrl: string;
  websiteCode: WebsiteCode;
  loginUrl: string;

  // Azure
  /*azureUrl: string;
  azureClientId: string;
  azureLoginUserFlow: string;
  azureResetPasswordUserFlow: string;
  azureTwoFactorUserFlow: string;
  */
  // 3rd Party Plugin
  is3rdPartyPluginEnable: boolean;
  gtmId: string;
}
