export enum RoutePath {
  TermOfUs = 'term',
  Home = 'home',
  FrequentlyAskedQuestion = 'faq',
  PrivacyPolicy = 'privacy',
  ContactUs = 'contact-us',
  Blog = 'blog',
  About = 'about',
  Destination = 'destinations',
  Product = 'products',
}
