// import { cmsFaqCategory } from 'projects/vacation-cms/src/app/ui/pages/frequently-ask-question/spec-helper';
// import { FEATURE_BLOG_TYPE } from '../ui/pages/home-page/components/feature-blogs/spec-helper/blog.spec-helper';
// import { PROPERTIES } from '../ui/pages/destination-page/spec-helper/properties.spec-helper';
// import { PROPERTY_DETAIL } from '../ui/pages/destination-page/spec-helper/propertyDetail.spec-helper';
// import { CAROUSEL } from '../ui/pages/home-page/components/vacation-banner/spec-helper/carousel.spec-helper';
// import { faqList } from '../ui/pages/frequently-asked-question-page/spec-helper';
// import { blogList } from '../ui/pages/blog-page/spec-helper';
// import { PRODUCT_LIST } from '../ui/pages/product-page/spec-helper/product.spec-helper';

export const MOCK_API_DATA = {
  // '/carousel$': CAROUSEL,
  // '/property$': PROPERTIES,
  // '/blog/type/homepage$': FEATURE_BLOG_TYPE,
  //'/cms/faq/category$': cmsFaqCategory,
  //'/faq$': faqList,
  // '/blog$': {
  //   is_success: true,
  //   message: '',
  //   amount: 3,
  //   data: blogList.slice(0, 3),
  // },
  /*'/blog/about$': {
    is_success: true,
    message: '',
    amount: blogList.length,
    data: blogList,
  },*/
  // '/hotelOffer$': PRODUCT_LIST,
  // '/property/.*$': PROPERTY_DETAIL,
};
