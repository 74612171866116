import { ThemeConfig } from './theme-config.model';
import COLOR_CONFIG from 'projects/shared-resources/src/custom-config/vacation-color-config';

export const THEME: ThemeConfig = {
  NAME: 'Yes&Vacations',
  FAV_ICON:
    'projects/shared-resources/src/assets/favicon/yesAndTravel.ico',
  LOGO: 'projects/shared-resources/src/assets/img/png/vacationLogo.png',
  IMAGES: {
    HOME_PAGE: {
      TITLE: 'assets/img/svg/homePageTitle.svg',
      MAIN_VISION: 'assets/img/webp/homePageMainVision.webp',
      EMAIL_SUBSCRIPTION_BACKGROUND:
        'assets/img/webp/emailSubscriptionBackground.webp',
    },
  },
  COLORS: COLOR_CONFIG,
};
