import { Routes } from '@angular/router';
import { RoutePath } from './enums';

export default [
  { path: '', redirectTo: RoutePath.Home, pathMatch: 'full' },
  {
    path: RoutePath.Blog,
    loadChildren: () => import('./pages/blog-page/blog-routes'),
  },
  {
    path: RoutePath.ContactUs,
    loadComponent: () =>
      import('./pages/contact-us-page/contact-us-page.component').then(
        (mod) => mod.default,
      ),
  },
  {
    path: RoutePath.About,
    loadComponent: () => import('./pages/about-page/about-page.component'),
  },
  {
    path: RoutePath.Destination,
    loadChildren: () =>
      import('./pages/destination-page/destination-routes'),
  },
  {
    path: RoutePath.Product,
    loadComponent: () =>
      import('./pages/product-page/product-page.component'),
  },
  {
    path: RoutePath.FrequentlyAskedQuestion,
    loadComponent: () =>
      import(
        './pages/frequently-asked-question-page/frequently-asked-question-page.component'
      ).then((mod) => mod.default),
  },
  {
    path: RoutePath.Home,
    loadComponent: () =>
      import('./pages/home-page/home-page.component').then(
        (mod) => mod.default,
      ),
  },
  {
    path: RoutePath.PrivacyPolicy,
    loadComponent: () =>
      import(
        './pages/privacy-policy-page/privacy-policy-page.component'
      ).then((mod) => mod.default),
  },
  { path: '**', redirectTo: RoutePath.Home, pathMatch: 'full' },
] as Routes;
